<template>
  <div class="fact-type-add-modal-content with-sticky-buttons">
    <div class="heading">
      <div class="text-lg bold">Add Fact</div>
      <div class="close-btn" @click="$emit('hide')"></div>
    </div>
    <div class="content">
      <div class="input-label">Fact type</div>
      <single-option-select
        placeholder="Select a Fact type"
        :value="factTypeId"
        :options="factTypes"
        value-field="id"
        @select="onFactTypeSelect"
      ></single-option-select>
      <person-fact-form
        v-if="factTypeId"
        :fact="fact"
        :options="factTypes"
        @change-value="changeFact('value', $event)"
        @change-place="changeFactPlace($event)"
        @change-start-date="changeFact('start_date', $event)"
        @change-end-date="changeFact('end_date', $event)"
        @change-cemetery="changeFact('cemetery', $event)"
        @change-info="changeFact('info', $event)"
      ></person-fact-form>
    </div>
    <div class="action-buttons right-align">
      <mcr-button class="save" :loading="familyTreePersonFactsUpdateLoadingState" @click="onSave">Create</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import SingleOptionSelect from '@common/elements/filters/SingleOptionSelect.vue';
import personRecordsPage from '@common/pages/personRecords/js/person-records-page';
import {getRandomString} from '@common/utils/utils';
import isEmpty from 'lodash/isEmpty';
import {mapGetters} from 'vuex';

import PersonFactForm from '@/components/common/tree/PersonFactForm';

export default {
  props: {
    personId: [Number, String],
  },
  data() {
    return {
      factTypeId: null,
      fact: {fact_type: null, _id: getRandomString(15), info: []},
    };
  },
  created() {
    if (isEmpty(this.$store.getters.familyTreePersonOptionsState)) {
      this.$store.dispatch('fetchFamilyTreePersonOptionsAction');
    }
  },
  computed: {
    ...mapGetters(['familyTreePersonFactsUpdateLoadingState']),
    factTypes() {
      const options = this.$store.getters.familyTreePersonOptionsState;
      if (!options || !options.fact_types) {
        return [];
      }
      return options.fact_types.map(type => {
        return {...type, name: `${type.label} (${type.category})`};
      });
    },
  },
  methods: {
    changeFact(key, value) {
      this.$set(this.fact, key, value);
    },
    changeFactPlace(place) {
      this.changeFact('place_id', place.place_id);
      this.changeFact('place_display_text', place.display_text);
    },
    onSave() {
      if (!this.factTypeId) {
        return;
      }
      const {fact_type, value, start_date, end_date, place_id, place_display_text, cemetery, info} = this.fact;
      const infoDict = {};
      info.forEach(item => {
        infoDict[item.key] = item.value;
      });
      const facts = [
        {
          fact_type_id: fact_type.id,
          value,
          start_date,
          end_date,
          place_id,
          place_display_text,
          cemetery_id: cemetery ? cemetery.object_id : null,
          info: infoDict,
        },
      ];
      this.$store.dispatch('updateFamilyTreePersonFactsAction', {personId: this.personId, facts}).then(() => {
        this.$emit('close');
        personRecordsPage.init(this);
      });
    },
    onFactTypeSelect(value) {
      this.factTypeId = value;
      this.changeFact(
        'fact_type',
        this.factTypes.find(type => type.id === value)
      );
    },
  },
  components: {SingleOptionSelect, McrButton, PersonFactForm},
  name: 'FactTypeAddModalContent',
};
</script>

<style lang="scss" scoped>
.fact-type-add-modal-content {
  .person-fact-form {
    margin-top: 20px;
  }

  .action-buttons {
    .save {
      min-width: 113px;
    }
  }
  @media only screen and (min-width: $main-menu-breakpoint) {
    width: 560px;
    padding-bottom: 0;
  }
}
</style>
